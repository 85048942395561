<template>
  <div class="about-us">
    <div class="wrapper">
      <div class="page-header page-header-small clear-filter" filter-color="primary">
          <div class="page-header-image"></div>
      </div>      
      <div class="section">
        <div class="about-description text-center">
          <div class="features-3">
            <div class="container">
              <div class="row">
                <div class="col-md-8 mr-auto ml-auto">
                  <h2 class="title">Who are we?</h2>
                  <h4 class="description">
                    DrRemo is an online facilitation platform that brings easy
                    wellness access to everyone, wherever you are. Our platform
                    enables remote consultations through video, call and chat
                    functionality as well as in person appointments through an
                    online booking system.
                    <br /><br />
                    Taking full advantage of what technology has to offer, we
                    focus on maximizing patient experience while providing
                    practitioners with exposure, marketability and back office
                    functionality. Finding the appropriate care and managing
                    your bookings has never been this easy.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section section-basic-components"  data-background-color="black">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="info info-hover">
                  <div class="icon icon-success icon-circle">
                    <i class="fa fa-ambulance"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-7 ml-auto mr-auto">
                <h2 class="title">Wellness</h2>
                <h5 class="description">
                  Mental and physical wellbeing is key to living a happy and healthy life. Search through a variety of wellness professions and professionals to find the necessary support and care to help you thrive.
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="section section-basic-components">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-7 ml-auto mr-auto">
                <h2 class="title">Wherever you are</h2>
                <h5 class="description">
                  DrRemo breaks down barriers and connects patients and practitioners. Physical distance no longer prevents you from getting the care you need.
                </h5>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="info info-hover">
                  <div class="icon icon-success icon-circle">
                    <i class="fa fa-wifi"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="separator-line separator-primary"></div>

        <div class="section section-basic-components">
          <div class="d-flex flex-row justify-content-center">
              <div class="flex-column align-self-center">
                  <card class="contactus-card text-center">
                      <h4 class="card-title"><b>Want to know more?</b></h4>
                      <p class="card-subtitle">If you would like to get more information, please do not hesitate to contact us <i class="far fa-smile"></i></p>
                      <n-button type="primary" @click="goToContactUs">CONTACT US</n-button>
                  </card>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { Card } from "@/components";
    import { Button } from "@/components";

    export default {
        components: {
            Card,
            [Button.name]: Button
        },

        methods: {
            goToContactUs() {                
                var self = this;
                self.$router.push({name:"contactus"}); 
            }
        },
        metaInfo() {
            return {
                title: "DrRemo | About Us",
                meta: [
                    {
                        name: "description",
                        content: "DrRemo is an online facilitation platform that connects patients with practitioners."
                    },
                    {
                      name: "robots",
                      content: "index, follow"
                    }
                ],
                link: [
                  {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/aboutus"
                  }
                ]
            }
        }
    }
</script>
